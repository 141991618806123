
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ name: "b-progress" })
export default class BProgress extends Vue {
  @Prop({ type: Number, default: 0 }) readonly value!: number;
  @Prop({ type: Number, default: 0 }) readonly size!: number;
  @Prop(Boolean) readonly label!: boolean;

  get barStyle(): Record<string, any> {
    const iVal = this.value < 100 ? this.value : 100;
    return {
      width: `${iVal}%`,
    };
  }

  get wrapStyle(): Record<string, any> {
    const obStyle = {};
    if (this.size) {
      this.$_.set(obStyle, "height", `${this.size}px`);
    }
    return obStyle;
  }

  get sLabel(): string {
    return this.label && !this.size ? `${this.value}%` : "";
  }

  get css(): Record<string, any> {
    const obCss = { "progress-bar": true };

    if (this.value >= 100) {
      this.$_.set(obCss, "bg-success", true);
    }

    return obCss;
  }
}
